<template>
    <div class="info-page">
        <!-- <br> -->
        <img width="90%" src="../../assets/checklogo1.png" alt="">
        <div class="tips">
            <p>查询结果</p>
            <el-divider class="divider"></el-divider>
        </div>
        <div class="content">
            <p>追溯码：<span style="color:red">{{index}}</span> </p>
            <p>没有找到该追溯码的相关信息</p>
            <p>建议拨打 <el-button type="text" :disabledt="true">021-68367505</el-button>让客服帮你</p>
        </div>
        <img width="100px" src="../../assets/qr.jpg" alt="">
        <span style="font-size:0.5rem">扫码获取更多帮助</span>
    </div>
</template>
<script>
export default {
    data(){
        return{
            index:this.$route.query.no
        }
    }
}
</script>

<style lang="scss" scoped>
.info-page{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}
.tips{
    margin-top: 20px;
    text-align: center;
    width: 100%;
    p{
        color: dodgerblue;
    }
    .divider{
        background-color: dodgerblue;
    }
}
.content{
    width: 90%;
    text-align: left;
    line-height: 30px;
}
</style>